import React from 'react'
import { Badge, Button, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { ClientSelectorHookForm } from 'methone/components/ClientSelector'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormSelect, HookFormInput } from 'methone/components/ui/Forms'
import { Pagination } from 'methone/components/ui/Pagination'
import { SortedTable, SortedTableSort } from 'methone/components/ui/SortedTable'
import { useQueryParams } from 'methone/hooks'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { findClientResume, listClientGroups } from 'methone/services/rest/clients'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { listITRs, deleteITR } from 'plugin-itr/services/itrs'
import { MENU_AREA } from 'plugin-itr/utils/constants'

import { Form, ITRContainer } from './styled'

interface FormData extends Omit<ListITRsFilters, 'clientId'> {
  client: Option
}

const bodyCellStyle: React.CSSProperties = { flexDirection: 'column' }

export function ItrPage(): JSX.Element {
  const [params, setParams] = useQueryParams<ListITRsFilters>({ page: 1, perPage: 10 })
  const { control, handleSubmit, reset } = useForm<FormData>({ defaultValues: { page: 1, perPage: 10 } })

  const [clientGroups, setClientGroups] = React.useState<Options>([])

  const { loading, mutate, run, data, error } = useRequest(listITRs, { manual: true })
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate()

  async function handleDelete({ id, nirf, ccir }: { id: string; nirf: string; ccir: string }): Promise<void> {
    try {
      setIsLoading(true)
      const message = 'Are you sure you want to delete the itr NIRF:{nirf}/CCIR:{ccir}?'
      if (await globalConfirmation.danger(i18n(message, { nirf, ccir }))) {
        await deleteITR(id)
        mutate((data) => ({ ...data, entries: data.entries.filter((user) => user.id !== id) }))
      }
    } catch (err) {
      handleHTTPRequestError(err)
    } finally {
      setIsLoading(false)
    }
  }

  function onSubmit(fields: FormData, _e: React.BaseSyntheticEvent<object, any, any>): void {
    setParams({
      page: params.page,
      perPage: params.perPage,
      orderBy: params.orderBy,
      direction: params.direction,
      ccir: fields.ccir,
      clientId: fields.client?.value,
      nirf: fields.nirf
    })
  }

  async function updateFormData(): Promise<void> {
    try {
      const client = params.clientId ? await findClientResume(params.clientId) : null

      const localClientGroups = await listClientGroups()
      setClientGroups(localClientGroups)

      reset({
        ...params,
        client: client ? { value: client.id, label: `${client.name} (${client.taxId})` } : null,
        ccir: params.ccir,
        nirf: params.nirf
      })
    } catch (err) {
      handleHTTPRequestError(err)
    }
  }

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error])

  React.useEffect(() => {
    run(params)
    updateFormData()
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ITRContainer>
      <Card style={{ marginBottom: 'var(--spacing-2)' }}>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ClientSelectorHookForm control={control} label={i18n('Client')} name="client" />
            <HookFormSelect control={control} label={i18n('Groups')} name="groups" isMulti options={clientGroups} />
            <HookFormInput control={control} label={i18n('NIRF')} name="nirf" />
            <HookFormInput control={control} label={i18n('CCIR')} name="ccir" />

            <div className="actions">
              <Button type="submit" variant="secondary">
                <i className="fas fa-filter" />
                {i18n('Apply filters')}
              </Button>
              <Button type="button" variant="primary" onClick={() => navigate(`/${MENU_AREA}/new`)}>
                <i className="fas fa-plus" />
                {i18n('New ITR')}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <SortedTable
        items={(data?.entries ?? [])?.map(({ client, ...itr }) => ({
          id: itr.id,
          client: (
            <div>
              {client.group && (
                <Badge bg="outline-secondary" style={{ marginRight: 'var(--spacing-2)' }}>
                  {client.group}
                </Badge>
              )}
              <span>{client.name}</span>
            </div>
          ),
          'nirf-ccir': (
            <>
              <Badge bg="secondary">{itr.nirf}</Badge>
              <Badge bg="secondary">{itr.ccir}</Badge>
            </>
          ),
          nirf: itr.nirf,
          ccir: itr.ccir
        }))}
        columns={[
          { title: i18n('Client'), columnRef: 'client', sortable: true },
          { bodyCellStyle, title: i18n('NIRF/CCIR'), columnRef: 'nirf-ccir', sortable: true, showOn: 'mobile' },
          { title: i18n('NIRF'), columnRef: 'nirf', sortable: true, width: 175, showOn: 'desktop' },
          { title: i18n('CCIR'), columnRef: 'ccir', sortable: true, width: 175, showOn: 'desktop' }
        ]}
        sort={{
          column: params.orderBy,
          direction: params.direction as SortedTableSort,
          onSort: (orderBy, direction) => setParams({ ...params, orderBy, direction })
        }}
        actions={[
          {
            icon: 'far fa-edit',
            onClick: ({ id }) => navigate(`/${MENU_AREA}/${id}`)
          },
          {
            icon: 'far fa-trash-alt',
            onClick: ({ id, nirf, ccir }) => handleDelete({ id, nirf, ccir }),
            showOn: 'desktop'
          }
        ]}
      />
      <div style={{ paddingTop: 'var(--spacing-2)' }}>
        <Pagination
          currentPage={Number(params.page)}
          onChangePage={(page) => setParams({ ...params, page })}
          totalPages={Number(data?.totalPages ?? 1)}
        />
      </div>
      <FixedLoading enabled={loading || isLoading} />
    </ITRContainer>
  )
}
