import { i18n } from 'methone/services/i18n'
import { menuService } from 'methone/services/menuService'
import { routerService } from 'methone/services/routerService'

import { ITRPermissions } from 'plugin-itr/shared/permissions'

import { enUS, ptBR } from './i18n'
import { ItrEditorPage, ItrPage } from './pages/Itr'
import { MENU_AREA } from './utils/constants'

i18n.addItems('pt-BR', ptBR.values)
i18n.addItems('en-US', enUS.values)

const permissions = [ITRPermissions.ITR_MANAGER, ITRPermissions.ITR_ADMIN]

menuService.addItem({ path: `/${MENU_AREA}`, title: i18n('ITR'), icon: 'fas fa-wheat-awn', element: ItrPage, permissions, order: 3 }) // eslint-disable-line prettier/prettier
routerService.addRoute({ path: `/${MENU_AREA}/:id`, title: i18n('ITR'), element: ItrEditorPage, permissions })
