import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { ClientSelectorHookForm } from 'methone/components/ClientSelector'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormTextarea } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { createITR, findITR, updateITR } from 'plugin-itr/services/itrs'
import { ITRResponseDTO } from 'plugin-itr/shared/dto/ITRResponseDTO'
import { ITRUpdateDTO } from 'plugin-itr/shared/dto/ITRUpdateDTO'
import { MENU_AREA } from 'plugin-itr/utils/constants'

import { EditorContainer, EditorFieldset } from './styled'

type CommonOmit = 'id' | 'clientId' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'client'
interface FormProps extends Omit<ITRResponseDTO, CommonOmit> {
  client: Option
}

const defaultValues: FormProps = {
  client: null,
  nirf: '',
  ccir: '',
  address: '',
  city: '',
  state: '',
  details: ''
}

export function ItrEditorPage(): JSX.Element {
  const { id } = useParams()

  const { control, handleSubmit, reset } = useForm<FormProps>({ defaultValues, mode: 'all' })

  const [isLoading, setIsLoading] = React.useState(true)

  const navigate = useNavigate()

  async function handleGetITRData(): Promise<void> {
    try {
      setIsLoading(true)

      if (id === 'new') {
        reset(defaultValues)
      } else {
        const response = await findITR(id)
        reset({
          client: { value: response.client.id, label: `${response.client.name} (${response.client.taxId})` },
          nirf: response.nirf,
          ccir: response.ccir,
          address: response.address,
          city: response.city,
          state: response.state,
          details: response.details
        })
      }

      setIsLoading(false)
    } catch (e) {
      handleHTTPRequestError(e)
    }
  }

  async function handleSubmitForm(data: FormProps): Promise<void> {
    try {
      setIsLoading(true)

      const bodyData: ITRUpdateDTO = {
        clientId: data.client.value,
        nirf: data.nirf,
        ccir: data.ccir,
        address: data.address,
        city: data.city,
        state: data.state,
        details: data.details
      }

      if (id === 'new') {
        const createdUser = await createITR(bodyData)
        navigate(`/${MENU_AREA}/${createdUser.id}`)
      } else {
        await updateITR(id, bodyData)
        await handleGetITRData()
      }
    } catch (e) {
      handleHTTPRequestError(e)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (id) {
      handleGetITRData()
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FixedLoading position="absolute" enabled={isLoading} />
      <EditorContainer>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <EditorFieldset>
            <Card>
              <Card.Body>
                <div className="form-row">
                  <ClientSelectorHookForm
                    control={control}
                    name="client"
                    rules={{ required: true }}
                    label={i18n('Client')}
                  />
                </div>

                <div className="form-row">
                  <HookFormInput control={control} name="address" rules={{ required: true }} label={i18n('Address')} />
                  <HookFormInput control={control} name="city" rules={{ required: true }} label={i18n('City')} />
                  <HookFormInput control={control} name="state" rules={{ required: true }} label={i18n('State')} />
                </div>

                <div className="form-row">
                  <HookFormInput control={control} name="nirf" rules={{ required: true }} label={i18n('NIRF')} />
                  <HookFormInput control={control} name="ccir" rules={{ required: true }} label={i18n('CCIR')} />
                </div>

                <div className="form-row">
                  <HookFormTextarea control={control} name="details" label={i18n('Details')} />
                </div>
              </Card.Body>
            </Card>
          </EditorFieldset>
          <Card className="actions-footer">
            <Card.Body>
              <div className="actions-area">
                <Button type="submit" variant="primary">
                  {i18n(id === 'new' ? 'Create' : 'Save')}
                </Button>
                <span>{i18n('or')}</span>
                <Button type="button" variant="link" onClick={() => navigate(`/${MENU_AREA}`)}>
                  {i18n('cancel')}
                </Button>
              </div>

              <div className="required-warn">* {i18n('Required fields')}</div>
            </Card.Body>
          </Card>
        </form>
      </EditorContainer>
    </>
  )
}
