import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { REST_ENDPOINTS, PLUGIN_NAME } from 'plugin-itr/shared/constants'
import { ITRCreateDTO } from 'plugin-itr/shared/dto/ITRCreateDTO'
import { ITRResponseDTO } from 'plugin-itr/shared/dto/ITRResponseDTO'
import { ITRResumeResponseDTO } from 'plugin-itr/shared/dto/ITRResumeResponseDTO'
import { ITRUpdateDTO } from 'plugin-itr/shared/dto/ITRUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.ITR}`

export async function listITRs(params?: ListITRsFilters): Promise<PaginationDTO<ITRResumeResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function findITR(id: string): Promise<ITRResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function createITR(data: ITRCreateDTO): Promise<ITRResumeResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateITR(id: string, data: ITRUpdateDTO): Promise<ITRResumeResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteITR(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
